import { ProduitData } from '@innedit/innedit';
import { useSelector } from 'dataformjs';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../core/sysext/Boutique/containers/Produit/components/List/Card';
import params from '../../core/sysext/Boutique/containers/Produit/params.json';
import { MenuProp } from '../../core/sysext/CMS/components/Menu';
import CMSView from '../../core/sysext/CMS/components/View';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';
import List from '../../core/sysext/CMS/containers/Boutique/components/new/List';

const ProduitsPage: FC<PageProps & { pageContext: { tab?: number } }> =
  props => {
    const {
      location,
      pageContext: { tab },
    } = props;
    const { t } = useTranslation();

    const boutique = useSelector((state: any) =>
      state.cms.boutiques
        ? state.cms.boutiques[state.cms.indexBoutique || 0]
        : undefined,
    );

    const model = new ProduitData({
      boutique,
      params,
      orderDirection: 'desc',
      orderField: 'datetime',
    });

    // const [categories, setCategories] = useState<
    //   firebase.default.firestore.QueryDocumentSnapshot[]
    // >([]);
    // const [wheres, setWheres] = useState<{ [key: string]: any }>({});

    // useEffect(() => {
    //   let unsub: () => void;
    //
    //   if (boutique) {
    //     const feature = new FeatureData(boutique, {
    //       collectionName: 'productCategories',
    //     });
    //     unsub = feature.watch(querySnapshot => {
    //       setCategories(querySnapshot.docs);
    //     });
    //   }
    //
    //   return () => {
    //     if (unsub) {
    //       unsub();
    //     }
    //   };
    // }, [boutique]);

    // const handleCategoriesOnChange = (
    //   event: SyntheticEvent<HTMLSelectElement>,
    // ) => {
    //   const { value } = event.currentTarget;
    //
    //   setWheres(oldWheres => {
    //     const newWheres: { [key: string]: any } = { ...oldWheres };
    //
    //     if (value) {
    //       newWheres.category = value;
    //     } else {
    //       delete newWheres.category;
    //     }
    //
    //     return newWheres;
    //   });
    // };

    const menu: MenuProp = {
      left: [],
      right: [
        // {
        //   onChange: handleCategoriesOnChange,
        //   options: categories.map(category => ({
        //     label: category.get('libelle'),
        //     value: category.id,
        //   })),
        //   placeholder: 'Aucune catégorie',
        //   type: 'select',
        // },
      ],
    };

    return (
      <Backoffice location={location}>
        <CMSView>
          <List
            {...props}
            allowSorting
            // autoLoading={Number(process.env.GATSBY_PRODUITS_AUTO_LOADING || 0)}
            displayDuplicate
            displayMode="search"
            formName="produit"
            item={Card}
            itemPathnamePrefix="/boutique/produits/"
            itemShowInPopup
            itemShowSideview
            listClassName="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-6"
            menu={menu}
            model={model}
            nbParPage={50}
            searchShow
            tabIndex={tab ?? 7}
            title={t('produits')}
            // wheres={wheres}
          />
        </CMSView>
      </Backoffice>
    );
  };

export default ProduitsPage;
