import {
  FirebaseFirestore,
  getMediaImageData,
  ProduitType,
} from '@innedit/innedit';
import classnames from 'classnames';
import compact from 'lodash/compact';
import moment from 'moment';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from '../../../../../../components/Button';
import IconCalendar from '../../../../../../icons/Calendar';
import IconCalendarCheck from '../../../../../../icons/CalendarCheck';
import IconEye from '../../../../../../icons/Eye';
import IconEyeSlash from '../../../../../../icons/EyeSlash';
import IconHandShake from '../../../../../../icons/HandShake';
import IconLevelUpAlt from '../../../../../../icons/LevelUpAlt';
import IconRedo from '../../../../../../icons/Redo';
import IconSort from '../../../../../../icons/Sort';
import ImagePhoto from '../../../../../../images/Photo';
import { colors } from '../../../../../../styles/theme';
import ListCard from '../../../../../CMS/containers/Boutique/components/new/Card';
import { ListItemProps } from '../../../../../CMS/containers/Boutique/components/new/props';

const BodyCard = styled.div`
  em {
    color: ${colors.secondary[500]};
    font-weight: 600;
  }
`;

const Card: FC<ListItemProps<ProduitType>> = ({
  docId,
  handleChangePosition,
  highlight,
  index,
  onClick,
  model,
  updateDocByIndex,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<ProduitType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc || !doc.exists) {
    return (
      <li className="flex flex-col !p-0 rounded">
        <div className="relative flex-auto flex flex-col-reverse bg-white-50 pb-full">
          <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
            <ImagePhoto color={colors.light[600]} size={100} />
          </div>
        </div>

        <div className="flex flex-row justify-around bg-white-50 border-t p-0.5 border-gray-300 rounded-b">
          <Button status="card-action">
            <IconCalendar />
          </Button>
          <Button status="card-action">
            <IconEye />
          </Button>
          <Button status="card-action">
            <IconLevelUpAlt />
          </Button>
          <Button status="card-action">
            <IconRedo />
          </Button>
          <Button status="card-action">
            <IconSort />
          </Button>
        </div>
      </li>
    );
  }

  const data = doc.data() as ProduitType;
  const { depth, height, photos, thumbnail, width } = data;

  const volume =
    Math.round(((depth || 0) * (height || 0) * (width || 0)) / 10000) / 100;

  let media;

  if (thumbnail) {
    media = {
      fullPath: thumbnail,
    };
  } else if (photos && photos.length > 0) {
    media = {
      fullPath: photos[0],
    };
  }

  const image =
    media &&
    getMediaImageData({
      media,
      breakpoints: [150, 240, 320, 480, 640],
      maxWidth: 640,
      transformations: ['c_contain', 'b_ffffff', 'r_1'],
    });

  const handleOnChangeIsOnlineStore = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    if (doc) {
      doc.ref.update({
        isOnlineStore: !doc.get('isOnlineStore'),
        updatedAt: moment().toISOString(),
      });
    }
  };

  const handleOnChangeDatetime = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const datetime = parseInt(moment().format('x'), 10);
    const updatedAt = moment().toISOString();
    doc.ref.update({
      datetime,
      updatedAt,
    });

    updateDocByIndex(
      index,
      {
        datetime,
        updatedAt,
      },
      0,
    );
  };

  const handleOnChangeRedo = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const datetime = parseInt(moment(doc.get('publishedAt')).format('x'), 10);
    const updatedAt = moment().toISOString();
    doc.ref.update({ datetime, updatedAt });

    updateDocByIndex(index, {
      datetime,
      updatedAt,
    });
  };

  const handleOnChangeReserved = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
    doc.ref.update({
      isReserved: !doc.get('isReserved'),
      reservedAt: moment().format('YYYY-MM-DDTHH:mm'),
      updatedAt: moment().toISOString(),
    });
  };

  const handleOnChangeSoldout = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    doc.ref.update({
      isInPOS: false,
      onSale: false,
      soldoutAt: moment().format('YYYY-MM-DDTHH:mm'),
      updatedAt: moment().toISOString(),
    });
  };

  const handleOnClick = (event: SyntheticEvent<HTMLElement>) => {
    onClick(event);
  };

  const soldOut = !data.onSale;
  // Pas de stock de disponible : produit épuisé - vendu
  // if (
  //   item.hasInventory &&
  //   !item.negativeStock &&
  //   (!item.qtyAvailable || 0 === item.qtyAvailable)
  // ) {
  //   soldOut = true;
  // }

  // Pas de canaux de distributions
  // const indisponible = !item.isOnlineStore && !item.isInPOS;

  const reserved = Boolean(data.isReserved);

  // const price = data.salePrice ? data.salePrice : data.price;
  // const prixBarre = data.salePrice ? data.price : data.retailPrice || undefined;
  // let discount = null;
  //
  // if (price && prixBarre) {
  //   switch (data.showDiscount) {
  //     case 'value':
  //       discount = `${price - prixBarre}€`;
  //       break;
  //
  //     case 'percentage':
  //     default:
  //       discount = `-${100 - (price / prixBarre) * 100}%`;
  //   }
  // }

  // let unite;
  // switch (data.unitPrice) {
  //   case 'lot':
  //     unite = 'lot';
  //     break;
  //   case 'paire':
  //     unite = 'paire';
  //     break;
  //   default:
  //     unite = 'pièce';
  // }

  return (
    <ListCard
      actionEditShow={false}
      actions={compact([
        {
          iconLeft: reserved ? IconCalendarCheck : IconCalendar,
          onClick: handleOnChangeReserved,
        },
        'classic' === data.productType &&
        !data.hasInventory &&
        data.onSale &&
        data.isOnlineStore
          ? {
              iconLeft: IconHandShake,
              onClick: handleOnChangeSoldout,
            }
          : null,
        {
          iconLeft: data.isOnlineStore ? IconEye : IconEyeSlash,
          onClick: event => handleOnChangeIsOnlineStore(event),
        },
        {
          iconLeft: IconLevelUpAlt,
          onClick: handleOnChangeDatetime,
        },
        {
          iconLeft: IconRedo,
          onClick: handleOnChangeRedo,
        },
      ])}
      actionsClassName="flex flex-row justify-around bg-white-50 border-t p-0.5 border-gray-300 rounded-b"
      className="flex flex-col !p-0 rounded"
      contentClassName="flex-auto flex flex-col-reverse bg-white-50 rounded-t"
      displayActionId={false}
      doc={doc}
      handleChangePosition={handleChangePosition}
      index={index}
      onClick={handleOnClick}
    >
      <figure
        className="w-full relative rounded-t cursor-pointer pb-full"
        data-index={index}
        onClick={handleOnClick}
        role="presentation"
      >
        <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
          <ImagePhoto color={colors.light[600]} size={100} />
        </div>
        {image && (
          <img
            {...image.images}
            alt={data.name}
            className="absolute rounded-t"
          />
        )}

        {(!data.isOnlineStore || reserved || soldOut || data.isDraft) && (
          <div
            className={classnames(
              'absolute top-0 right-0 bottom-0 left-0',
              'bg-white bg-opacity-60 rounded-t',
              'flex flex-col space-y-2 items-center justify-center',
            )}
          >
            {data.isDraft && (
              <span className="bg-white-200 bg-opacity-50 rounded px-1 text-warning-800">
                brouillon
              </span>
            )}
            {soldOut && (
              <>
                {'soldout' === data.fabricable ? (
                  <span>sur commande</span>
                ) : (
                  <span className="bg-white-200 bg-opacity-50 rounded px-1 text-danger-800">
                    vendu
                  </span>
                )}
              </>
            )}
            {reserved && (
              <div className="flex flex-col items-center bg-white-200 bg-opacity-50 rounded px-1 text-warning-800">
                <span>réservé</span>
                <span>
                  depuis le {moment(data.reservedAt).format('DD/MM/YY')}
                </span>
              </div>
            )}
            {!data.isOnlineStore && (
              <span className="bg-white-200 bg-opacity-50 rounded px-1 text-info-800">
                caché
              </span>
            )}
          </div>
        )}

        <div className="absolute">
          {data.isInPOS && <div className="bulle isInPOS" />}
          {data.hasInventory && data.negativeStock && (
            <div className="bulle negativeStock">∞</div>
          )}
        </div>
      </figure>
      <BodyCard className="py-0.5 px-2 border-b text-sm">
        <h2
          className="truncate text-center"
          dangerouslySetInnerHTML={{
            __html: highlight?.name?.value || data.name,
          }}
        />
        <div className="flex flex-row justify-between text-light-800">
          {(0 === volume || !data.price) && (
            <span className="text-danger-500">⚠</span>
          )}
          {data.price && (
            <span>
              {data.price} € {data.unitPrice}
            </span>
          )}
          <span
            className="text-light-700"
            dangerouslySetInnerHTML={{
              __html: highlight?.sku?.value || data.sku,
            }}
          />
        </div>
      </BodyCard>
    </ListCard>
  );
};

export default Card;
